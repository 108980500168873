<script>
    import DatePicker from 'vue2-datepicker'
    import {mapState} from "vuex";
    import PrintQuestionnaire from "@components/PrintQuestionnaire";

    export default{
        components: {PrintQuestionnaire, DatePicker},
        props:{
            athlete: {
                default: () => {
                    return {}
                },
                type: Object
            },
          isSharedQuestionnaire: {
              type: Boolean,
            defaultValue: false
          },
          sharedQuestionSettings: Object,
          sharedQuestionnaireData: Object,
          sharedShortQuestionnaireData: Object,
          sharedCustomQuestionnaireData: Object,
          sharedCustomQuestionnaires: Array
        },
        computed:{
          ...mapState({
            shortQuestionnaireStructure: state => state.athlete._shortQuestionnaireStructure,
            questionnaireStructure: state => state.athlete._questionnaireStructure
          }),

          customQuestionnaires(){
            return this.isSharedQuestionnaire ? this.sharedCustomQuestionnaires :  this.$store.state.auth.questionnaires
          },

          getQuestionnaireTabs(){

            if(this.isSharedQuestionnaire){
              if(this.shouldShow('questionnaire')){
                return this.questionnaireStructure.tabs.filter(x => this.shouldShow('questionnaire', x))
              }else{
                return [];
              }
            }
            return this.questionnaireStructure.tabs;
          }
        },
        data(){
            return {
                shortQuestionnaireTabId: 0,
                shortQuestionnaire: null,
                questionnaire:{
                    q1      : null, q1_1    : null, q1_2    : null, q1_3    : null, q1_4    : null, q1_5    : null, q1_6    : null,
                    q2      : null, q2_1    : null, q2_2    : null, q2_3    : null, q2_4    : null, q2_5    : null, q2_6    : null,
                    q3      : null, q3_1    : null, q3_2    : null, q3_3    : null, q3_4    : null, q3_5    : null, q3_6    : null,
                    q4      : null, q4_1    : null, q5      : null, q5_1    : null, q5_1_1  : null,
                    q6      : null, q6_1    : null, q7      : null, q7_1    : null,
                    q8      : null, q8_1    : null, q8_2    : null, q8_3    : null,
                    q9      : null, q10     : null, q11     : null, q11_1   : null,
                    q12     : null, q13     : null, q14     : null, q14_1   : null, q14_2   : null, q15     : null, q16     : null,
                    q17_1   : null, q17_2   : null, q17_3   : null, q17_4   : null, q17_5   : null, q17_6   : null, q17_7   : null,
                    q18     : null, q18_1   : null,
                    q19_1   : null, q19_2   : null, q19_3   : null, q19_4   : null, q19_5   : null, q19_6   : null, q19_7   : null, q19_8   : null,
                    q20_1   : null, q20_2   : null, q20_3   : null, q20_4   : null, q20_5   : null,
                    q21_1   : null, q21_2   : null, q21_3   : null, q21_4   : null, q21_5   : null, q21_6   : null, q21_7   : null, q21_8   : null,
                    q22_1   : null, q22_2   : null, q22_3   : null, q22_4   : null,
                    q23_1   : null, q23_2   : null, q23_3   : null, q23_4   : null, q23_5   : null, q23_6   : null, q23_7   : null, q23_7_1   : null, q23_8   : null, q23_9   : null,
                },
                questionnaireTabId: 0,
                isUnchanged: true,
                customQuestionnaireAnswers: {},
                printWindowKey: 0
            }
        },

        created(){


          if(this.isSharedQuestionnaire){

            this.questionnaire = {...this.sharedQuestionnaireData};
            this.shortQuestionnaire = {...this.sharedShortQuestionnaireData};
            this.customQuestionnaireAnswers = {...this.sharedCustomQuestionnaireData};

          }else{
            this.getQuestionnaire();
            this.getShortQuestionnaire();
            this.getCustomQuestionnaire();
          }

          this.formatCustomQuestionnaireData();
        },
        beforeDestroy() {
            if(!this.isUnchanged && !this.isSharedQuestionnaire){
                this.updateQuestionnaire();
            }
        },
        methods:{
            shouldEnable(value){return value === '1' || value === 1;},

            getQuestionnaire() {
                this.$axios.get(`/athlete/${this.athlete.id}/questionnaire`).then(result => {
                    this.questionnaire = result.data;
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Το ερωτηματολόγιο δεν φόρτωσε!'});
                });
            },

            getShortQuestionnaire(){
                this.$axios.get(`/athlete/${this.athlete.id}/short-questionnaire`).then(result => {
                    this.shortQuestionnaire = result.data;
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Το σύντομο ερωτηματολόγιο δεν φόρτωσε!'});
                });
            },


            getCustomQuestionnaire(){
              this.$axios.get(`/athlete/${this.athlete.id}/custom-questionnaire`).then(result => {
                this.customQuestionnaireAnswers = result.data !== "" ? result.data : {};
                this.formatCustomQuestionnaireData();
              }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Το ερωτηματολόγιο δεν φόρτωσε!'});
              });
            },

            formatCustomQuestionnaireData(){
              for(let questionnaire of this.customQuestionnaires){
                if(!this.customQuestionnaireAnswers[questionnaire.id]){
                  this.customQuestionnaireAnswers[questionnaire.id] = {};
                  if(!questionnaire.questionnaire.questions) continue;
                  for(let question of questionnaire.questionnaire.questions){
                    if(!this.customQuestionnaireAnswers[questionnaire.id][question.id]) this.customQuestionnaireAnswers[questionnaire.id][question.id] = null
                  }
                }
              }
              this.printWindowKey++;

            },

            async updateQuestionnaire(){

              if(this.isSharedQuestionnaire){
                this.$emit('updateClicked', {
                  questionnaire: this.questionnaire,
                  shortQuestionnaire: this.shortQuestionnaire,
                  customQuestionnaireAnswers: this.customQuestionnaireAnswers
                });
                this.isUnchanged = true;
              }else {

                await this.updateShortQuestionnaire();
                await this.updateCustomQuestionnaire();
                this.$axios.put(`/athlete/${this.athlete.id}/questionnaire`, {questionnaire: this.questionnaire}).then(result => {
                  this.questionnaire = result.data;
                  this.$notify({
                    group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'),
                    text: this.$t('success.text')
                  });
                  this.isUnchanged = true;
                });
              }
            },

            async updateShortQuestionnaire(){
                this.$axios.put(`/athlete/${this.athlete.id}/short-questionnaire`, {shortQuestionnaire: this.shortQuestionnaire}).then( result =>{
                    this.shortQuestionnaire = result.data;
                    this.isUnchanged = true;
                });
            },

            async updateCustomQuestionnaire(){
              this.$axios.put(`/athlete/${this.athlete.id}/custom-questionnaire`, {questionnaire: this.customQuestionnaireAnswers}).then( result =>{
                this.isUnchanged = true;
              });
            },

            markAsDirty() {
              this.isUnchanged = false;
            },

          shouldShow(type, subType){
              if(!this.isSharedQuestionnaire) return true;

              if(type === 'shortQuestionnaire'){

                if(!subType) return this.sharedQuestionSettings.visible_questionnaires.shortQuestionnaire.show;

                return this.sharedQuestionSettings.visible_questionnaires.shortQuestionnaire[subType];
              }
              if(type === 'questionnaire'){
                if(!subType) return this.sharedQuestionSettings.visible_questionnaires.questionnaire.show;
                return this.sharedQuestionSettings.visible_questionnaires.questionnaire[subType];
              }

              return true;
          }

        }
    }
</script>
<style>
    .questionnaire-inner-1{
        margin-left:25px;
    }
    .questionnaire-inner-1 b-form-input{
        width:100%;
    }
    label{
        font-weight:normal;
    }
    .my-1{
        padding:10px 0;
    }
    .questionnaire-inner-1 .my-1{
        padding: initial;
    }
</style>
<template>
    <div class="questionnaire-container">

      <div v-if="!isSharedQuestionnaire" class="row">
        <div class="col-12">
          <print-questionnaire :key="printWindowKey" v-if="shortQuestionnaire && questionnaire"  :questionnaire="questionnaire" :short-questionnaire="shortQuestionnaire"
                               :athlete-id="athlete.id"
                               :questionnaire-structure="questionnaireStructure"
                               :custom-questionnaires-list="customQuestionnaires"
                               :custom-questionnaire-answers="customQuestionnaireAnswers"
                               :short-questionnaire-structure="shortQuestionnaireStructure"></print-questionnaire>

        </div>
      </div>


      <b-tabs v-model="questionnaireTabId" pills justified vertical class="questionnaire-tabs"  nav-class="nav-tabs nav-bordered pt-3">
            <b-form @submit.prevent="updateQuestionnaire" @change="markAsDirty">
                <b-tab title="Σύντομο Ιστορικό" v-if="shouldShow('shortQuestionnaire')">
                    <div v-if="(questionnaireTabId === 0 && shortQuestionnaire) || isSharedQuestionnaire" class="card">
                        <div class="card-body">
                            <h4 class="mb-3 bg-light p-2">Σύντομο Ιστορικό </h4>
                            <b-tabs v-model="shortQuestionnaireTabId" class="short-questionnaire" fill>

                              <b-tab v-for="(tab,index) in shortQuestionnaireStructure.tabs" :key="tab" :title="shortQuestionnaireStructure[tab].title"
                                     v-if="shouldShow('shortQuestionnaire', tab)">
                                <div v-if="shortQuestionnaireTabId === index || isSharedQuestionnaire">
                                  <div class="card-body">
                                    <h4 class="mb-3 bg-light p-2">{{ shortQuestionnaireStructure[tab].title }} </h4>

                                    <div v-for="question in shortQuestionnaireStructure[tab].questions" :key="question.key">
                                      <b-row v-if="question.type !== 'notice'" class="my-1">
                                        <b-col sm="3">
                                          <label :for="'sq-'+question.key">{{question.question}}</label>
                                        </b-col>
                                        <b-col sm="7">

                                          <b-form-group v-if="question.type === 'date'" :id="'sq-'+question.key" :label-for="'sq-'+question.key">
                                            <date-picker v-model="shortQuestionnaire[question.key]" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
                                          </b-form-group>

                                          <b-form-radio-group v-if="question.type === 'YesNo'" :id="'sq-'+question.key"
                                                              v-model="shortQuestionnaire[question.key]">
                                            <b-form-radio value="1">Ναι</b-form-radio>
                                            <b-form-radio value="0">Όχι</b-form-radio>
                                          </b-form-radio-group>
                                          <b-form-radio-group v-if="question.type === 'Options'" :id="'sq-'+question.key"
                                                              v-model="shortQuestionnaire[question.key]">
                                            <b-form-radio v-for="option in question.options" :key="option.value" :value="option.value">
                                              {{ option.text }}</b-form-radio>
                                          </b-form-radio-group>
                                          <b-form-input v-if="question.type === 'text'" :id="'sq-'+question.key"
                                                        v-model="shortQuestionnaire[question.key]" type="text"></b-form-input>

                                          <b-form-textarea v-if="question.type === 'textarea'"
                                                           :id="'sq-'+question.key" v-model="shortQuestionnaire[question.key]"
                                                           type="textarea" :rows="question.rows"></b-form-textarea>

                                        </b-col>
                                      </b-row>

                                      <div v-if="question.type === 'notice'">
                                        <strong>{{question.text}}</strong>
                                      </div>

                                      <div v-if="question.subQuestions && (shouldEnable(shortQuestionnaire[question.key]) || question.type === 'Options')" class="questionnaire-inner-1">

                                        <div v-if="!subQuestion.value || parseInt(subQuestion.value) === parseInt(shortQuestionnaire[question.key])" v-for="subQuestion in question.subQuestions" :key="subQuestion.key">
                                          <b-row class="my-1 align-items-center">
                                            <b-col sm="2">
                                              <label :for="'sq-'+subQuestion.key">{{ subQuestion.question }}</label>
                                            </b-col>
                                            <b-col sm="7">
                                              <b-form-input v-if="subQuestion.type === 'text'" :id="'sq-'+subQuestion.key"
                                                            v-model="shortQuestionnaire[subQuestion.key]" type="text"></b-form-input>

                                              <b-form-textarea v-if="subQuestion.type === 'textarea'"
                                                               :id="'sq-'+subQuestion.key" v-model="shortQuestionnaire[subQuestion.key]"
                                                               type="textarea" :rows="subQuestion.rows"></b-form-textarea>

                                            </b-col>
                                          </b-row>
                                        </div>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </b-tab>

                <b-tab v-for="(tab,index) in getQuestionnaireTabs" :key="tab" :title="questionnaireStructure[tab].title">
                  <div v-if="questionnaireTabId === (index+1) || (isSharedQuestionnaire && questionnaireTabId === (index+(shouldShow('shortQuestionnaire') ? 1 : 0)))" class="card">
                    <div class="card-body">
                      <h4 class="mb-3 bg-light p-2">{{ questionnaireStructure[tab].title }} </h4>

                      <div v-for="question in questionnaireStructure[tab].questions" :key="question.key">
                        <b-row v-if="question.type !== 'notice'" class="my-1">
                          <b-col sm="3">
                            <label :for="question.key">{{question.question}}</label>
                          </b-col>
                          <b-col sm="7">
                            <b-form-radio-group v-if="question.type === 'YesNo'" :id="question.key"
                                                v-model="questionnaire[question.key]">
                              <b-form-radio value="1">Ναι</b-form-radio>
                              <b-form-radio value="0">Όχι</b-form-radio>
                            </b-form-radio-group>
                            <b-form-radio-group v-if="question.type === 'Options'" :id="question.key"
                                                v-model="questionnaire[question.key]">
                              <b-form-radio v-for="option in question.options" :key="option.value" :value="option.value">
                                {{ option.text }}</b-form-radio>
                            </b-form-radio-group>
                            <b-form-input v-if="question.type === 'text'" :id="question.key"
                                          v-model="questionnaire[question.key]" type="text"></b-form-input>

                            <b-form-textarea v-if="question.type === 'textarea'"
                                             :id="question.key" v-model="questionnaire[question.key]"
                                             type="textarea" :rows="question.rows"></b-form-textarea>

                          </b-col>
                        </b-row>

                        <div v-if="question.type === 'notice'">
                          <strong>{{question.text}}</strong>
                        </div>

                        <div v-if="question.subQuestions &&
                            (shouldEnable(questionnaire[question.key]) || question.type === 'Options')" class="questionnaire-inner-1">

                          <div v-if="!subQuestion.value || parseInt(subQuestion.value) === parseInt(questionnaire[question.key])" v-for="subQuestion in question.subQuestions" :key="subQuestion.key">
                            <b-row class="my-1 align-items-center">
                              <b-col sm="2">
                                <label :for="subQuestion.key">{{ subQuestion.question }}</label>
                              </b-col>
                              <b-col sm="7">
                                <b-form-input v-if="subQuestion.type === 'text'" :id="subQuestion.key"
                                              v-model="questionnaire[subQuestion.key]" type="text"></b-form-input>

                                <b-form-textarea v-if="subQuestion.type === 'textarea'"
                                                 :id="subQuestion.key" v-model="questionnaire[subQuestion.key]"
                                                 type="textarea" :rows="subQuestion.rows"></b-form-textarea>

                                <b-form-radio-group v-if="subQuestion.type === 'YesNo'"
                                                    :id="subQuestion.key" v-model="questionnaire[subQuestion.key]">
                                  <b-form-radio value="1">Ναι</b-form-radio>
                                  <b-form-radio value="0">Όχι</b-form-radio>
                                </b-form-radio-group>

                                <b-form-radio-group v-if="subQuestion.type === 'Options'" :id="subQuestion.key"
                                                    v-model="questionnaire[subQuestion.key]">
                                  <b-form-radio v-for="option in subQuestion.options" :key="option.value" :value="option.value">
                                    {{ option.text }}</b-form-radio>
                                </b-form-radio-group>

                              </b-col>
                            </b-row>
                            <div v-if="subQuestion.subQuestions && shouldEnable(questionnaire[subQuestion.key])" class="questionnaire-inner-1">

                              <div v-for="subSubQuestion in subQuestion.subQuestions" :key="subSubQuestion.key">
                                <b-row class="my-1 align-items-center">
                                  <b-col sm="2">
                                    <label :for="subSubQuestion.key">{{ subSubQuestion.question }}</label>
                                  </b-col>
                                  <b-col sm="7">
                                    <b-form-input v-if="subSubQuestion.type === 'text'" :id="subSubQuestion.key"
                                                  v-model="questionnaire[subSubQuestion.key]" type="text"></b-form-input>

                                    <b-form-textarea v-if="subSubQuestion.type === 'textarea'"
                                                     :id="subSubQuestion.key" v-model="questionnaire[subSubQuestion.key]"
                                                     type="textarea" :rows="subSubQuestion.rows"></b-form-textarea>

                                    <b-form-radio-group v-if="subSubQuestion.type === 'YesNo'"
                                                        :id="subSubQuestion.key" v-model="questionnaire[subSubQuestion.key]">
                                      <b-form-radio value="1">Ναι</b-form-radio>
                                      <b-form-radio value="0">Όχι</b-form-radio>
                                    </b-form-radio-group>

                                  </b-col>
                                </b-row>
                              </div>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab v-for="customQuestionnaire in customQuestionnaires" :key="customQuestionnaire.id" :title="customQuestionnaire.name">

                  <b-card >
                    <h4 class="mb-3 bg-light p-2">{{ customQuestionnaire.name }}</h4>
                    <div v-for="question in customQuestionnaire.questionnaire.questions" :key="customQuestionnaire.id + '-' + question.id">
                      <b-row class="my-1">
                        <b-col sm="3">
                          <label :for="question.id">{{ question.question }}</label>
                        </b-col>
                        <b-col sm="7">
                          <b-form-group v-if="question.type === 'yes_no'" >
                            <b-form-radio-group :id="question.id" v-model="customQuestionnaireAnswers[customQuestionnaire.id][question.id]">
                              <b-form-radio value="1">Ναι</b-form-radio>
                              <b-form-radio value="0">Όχι</b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                          <b-form-textarea v-if="question.type === 'long_text'"
                                           :id="question.id" v-model="customQuestionnaireAnswers[customQuestionnaire.id][question.id]"
                                           type="textarea" rows="3"></b-form-textarea>

                          <b-form-input v-if="question.type === 'text'" v-model="customQuestionnaireAnswers[customQuestionnaire.id][question.id]"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-tab>
                <b-tab v-if="customQuestionnaires.length < 5 && !isSharedQuestionnaire" style="padding:0;" @click="$router.push({name:'questionnaires'})">
                  <template v-slot:title>
                    <hr style="margin-top:0!important;">
                    <router-link :to="{name:'questionnaires'}" class="text-left" style="padding:0!important;width:100%;">Προσθήκη ερωτηματολογίου</router-link>
                  </template>
                </b-tab>

                <b-form-group id="button-group" class="mt-2 text-right">
                  <save-button type="submit" :disabled="isUnchanged"></save-button>
                </b-form-group>
            </b-form>
        </b-tabs>
    </div>
</template>
