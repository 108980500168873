<template>
  <div>
    <div class="text-right">
      <b-button variant="light" class="mr-2 mb-2 " @click="openShareOptionsModal"><span class="fe-share mr-1"></span> Κοινοποίηση ερωτηματολογίου σε πελάτη</b-button>
      <b-button variant="primary" class="mr-2 mb-2 " @click="previewQuestionnaire"><span class="fe-eye mr-1"></span> Προβολή συμπληρωμένων απαντήσεων</b-button>
      <b-button variant="success" class="mb-2" @click="printQuestionnaire"><i class="fe-printer mr-1"></i> Εκτύπωση</b-button>
    </div>

    <b-modal ref="share-options" title="Κοινοποίηση ιστορικού προς συμπλήρωση" hide-footer @hidden="saveIfChanged">


      <div v-if="shareOptions">
        <b-alert v-if="shareOptions.is_active" variant="success" show>
          Η κοινοποίηση ερωτηματολογίου είναι ενεργή και ο πελάτης σας μπορεί να δει και να επεξεργαστεί τις απαντήσεις.
        </b-alert>
        <b-alert v-else show variant="warning">
          Η κοινοποίηση ερωτηματολογίου δεν είναι ενεργή. Ο πελάτης σας δεν μπορεί να δει και να επεξεργαστεί τα ερωτηματολόγια.
        </b-alert>

        <div v-if="shareOptions.is_active && shareOptions.key" class="text-center" @click="copyKey">
          <p>{{getShareLink}}</p>
          <b-button  size="sm" variant="primary" class="mb-3">

            <span v-if="!copied">
                 <span class="fe-copy"></span> Αντιγραφή συνδέσμου κοινοποίησης
            </span>
            <span v-else>
              <span class="fe-check"></span> Έχει αντιγραφεί
            </span>


          </b-button>
        </div>

      </div>
      <b-form-checkbox v-if="shareOptions" v-model="shareOptions.is_active" switch @change="saveShareOptions">Ενεργοποίηση κοινοποίησης</b-form-checkbox>

      <div v-if="shareOptions && shareOptions.is_active" class="visible-questionnaires">

        <h6>Ποια ερωτηματολόγια θέλετε να μπορεί να δει / συμπληρώσει ο πελάτης σας;</h6>
        <div  class="px-3 py-1">
          <b-form-checkbox id="short-questionnaire" v-model="shareOptions.visible_questionnaires.shortQuestionnaire.show"
                           name="short-questionnaire">Κοινοποίηση Σύντομου ιστορικού;</b-form-checkbox>
          <div v-if="shareOptions.visible_questionnaires.shortQuestionnaire.show" class="pl-3">
            <b-form-checkbox v-for="tab in shortQuestionnaireStructure.tabs" :key="tab" :id="`short-questionnaire-${tab}`"
                             v-model="shareOptions.visible_questionnaires.shortQuestionnaire[tab]"
                             :name="`short-questionnaire-${tab}`">{{ shortQuestionnaireStructure[tab].title }};</b-form-checkbox>
          </div>
        </div>

        <div class="px-3 py-1">
          <b-form-checkbox id="questionnaire" v-model="shareOptions.visible_questionnaires.questionnaire.show"
                           name="questionnaire">Κοινοποίηση ιστορικού;</b-form-checkbox>
          <div v-if="shareOptions.visible_questionnaires.questionnaire.show" class="pl-3">
            <b-form-checkbox v-for="tab in questionnaireStructure.tabs" :key="tab" :id="`questionnaire-${tab}`"
                             v-model="shareOptions.visible_questionnaires.questionnaire[tab]"
                             :name="`questionnaire-${tab}`">{{ questionnaireStructure[tab].title }};</b-form-checkbox>
          </div>
        </div>

        <div v-if="customQuestionnairesList.length > 0" class="px-3 py-1">
          <b-form-checkbox id="c-questionnaire" v-model="shareOptions.visible_questionnaires.customQuestionnaires.show"
                           name="c-questionnaire">Κοινοποίηση Προσωπικών ερωτηματολόγια;</b-form-checkbox>
          <div v-if="customQuestionnairesList && shareOptions.visible_questionnaires.customQuestionnaires.show" class="pl-3">
            <b-form-checkbox v-for="tab in customQuestionnairesList" :key="tab.id" :id="`c-questionnaire-${tab.id}`"
                             v-model="shareOptions.visible_questionnaires.customQuestionnaires[tab.id]"
                             :name="`c-questionnaire-${tab}`">{{ findCustomQuestionnaire(tab.id).name }};</b-form-checkbox>
          </div>
        </div>
      </div>


      <div class="my-3 text-right">
        <b-button variant="success" :disabled="!shareOptionsChanged" @click="saveShareOptions"> {{$t('saveChanges')}}</b-button>
      </div>

    </b-modal>

    <b-modal ref="print-options" title="Εκτύπωση Ιστορικού" hide-footer>

      <div class="px-3 py-1">
        <b-form-checkbox id="short-questionnaire" v-model="printOptions.shortQuestionnaire.show"
                         name="short-questionnaire">Εκτύπωση σύντομου ιστορικού;</b-form-checkbox>
        <div v-if="printOptions.shortQuestionnaire.show" class="pl-3">
          <b-form-checkbox v-for="tab in shortQuestionnaireStructure.tabs" :key="tab" :id="`short-questionnaire-${tab}`"
                           v-model="printOptions.shortQuestionnaire[tab]"
                           :name="`short-questionnaire-${tab}`">{{ shortQuestionnaireStructure[tab].title }};</b-form-checkbox>
        </div>
      </div>

      <div class="px-3 py-1">
        <b-form-checkbox id="questionnaire" v-model="printOptions.questionnaire.show"
                         name="questionnaire">Εκτύπωση ιστορικού;</b-form-checkbox>
        <div v-if="printOptions.questionnaire.show" class="pl-3">
          <b-form-checkbox v-for="tab in questionnaireStructure.tabs" :key="tab" :id="`questionnaire-${tab}`"
                           v-model="printOptions.questionnaire[tab]"
                           :name="`questionnaire-${tab}`">{{ questionnaireStructure[tab].title }};</b-form-checkbox>
        </div>
      </div>

      <div v-if="customQuestionnairesList.length > 0" class="px-3 py-1">
        <b-form-checkbox id="c-questionnaire" v-model="printOptions.customQuestionnaires.show"
                         name="c-questionnaire">Προσωπικά ερωτηματολόγια;</b-form-checkbox>
        <div v-if="customQuestionnairesList && printOptions.customQuestionnaires.show" class="pl-3">
          <b-form-checkbox v-for="tab in customQuestionnairesList" :key="tab.id" :id="`c-questionnaire-${tab.id}`"
                           v-model="printOptions.customQuestionnaires[tab.id]"
                           :name="`c-questionnaire-${tab}`">{{ findCustomQuestionnaire(tab.id).name }};</b-form-checkbox>
        </div>
      </div>

      <div class="text-center">

        <b-button variant="primary" @click="initializePrint(printTypes.print)"><span class="icon-printer"></span> Εκτύπωση</b-button>
        <b-button variant="primary" @click="initializePrint(printTypes.save)" class="ml-2"><span class="icon-printer"></span> Αποθήκευση ως PDF</b-button>
      </div>


    </b-modal>


    <b-modal ref="preview-area" size="xl" scrollable hide-footer title="Προβολή συμπληρωμένων απαντήσεων">


      <b-tabs>
        <b-tab title="Σύντομο Ιστορικό">
          <div>
            <div v-for="tab in shortQuestionnaireStructure.tabs" class="py-2" style="page-break-inside: avoid; clear:both">

              <h4>{{shortQuestionnaireStructure[tab].title}}</h4>


              <question-render v-for="(question,index) in shortQuestionnaireStructure[tab].questions"
                               :key="`pr-q-${question.key}`"
                               :index="index"
                               :question="question"
                               :questionnaire="shortQuestionnaire"></question-render>
            </div>
          </div>
        </b-tab>
        <b-tab title="Εκτενές Ιστορικό">
          <div>
            <div v-for="tab in questionnaireStructure.tabs" class="py-2" style="page-break-inside: avoid; clear:both">

              <h4>{{questionnaireStructure[tab].title}}</h4>


              <question-render v-for="(question,index) in questionnaireStructure[tab].questions"
                               :key="`pr-q-${question.key}`"
                               :index="index"
                               :question="question"
                               :questionnaire="questionnaire"></question-render>
            </div>
          </div>
        </b-tab>

        <b-tab title="Προσωπικά ερωτηματολόγια">
          <div>
            <div v-for="tab in customQuestionnairesList" class="py-2" style="page-break-inside: avoid; clear:both">

              <h4>{{findCustomQuestionnaire(tab.id).name}}</h4>


              <div v-if="customQuestionnaireAnswers[tab.id][question.id]" v-for="question in findCustomQuestionnaire(tab.id).questionnaire.questions">
                <question-render-item :question="question" :value="customQuestionnaireAnswers[tab.id][question.id]"></question-render-item>
              </div>

            </div>
          </div>
        </b-tab>
      </b-tabs>




    </b-modal>
    <div id="print-area" v-if="isPrinting" :class="isPrinting ? 'print-version' : 'view-version'" ref="questionnaires-print-area">

      <print-header></print-header>

      <div v-if="printOptions.shortQuestionnaire.show ">
        <div v-if="printOptions.shortQuestionnaire[tab]" v-for="tab in shortQuestionnaireStructure.tabs" class="py-2" style="page-break-inside: avoid; clear:both">

          <h4>{{shortQuestionnaireStructure[tab].title}}</h4>


          <question-render v-for="(question,index) in shortQuestionnaireStructure[tab].questions"
                           :key="`pr-q-${question.key}`"
                           :index="index"
                           :question="question"
                           :questionnaire="shortQuestionnaire"></question-render>
        </div>
      </div>

      <div v-if="printOptions.questionnaire.show">
        <div v-if="printOptions.questionnaire[tab]" v-for="tab in questionnaireStructure.tabs" class="py-2" style="page-break-inside: avoid; clear:both">

          <h4>{{questionnaireStructure[tab].title}}</h4>


          <question-render v-for="(question,index) in questionnaireStructure[tab].questions"
                           :key="`pr-q-${question.key}`"
                           :index="index"
                           :question="question"
                           :questionnaire="questionnaire"></question-render>
        </div>
      </div>


      <div v-if="printOptions.customQuestionnaires.show">
        <div v-if="printOptions.customQuestionnaires[tab.id] && customQuestionnaireAnswers[tab.id]" v-for="tab in customQuestionnairesList" class="py-2" style="page-break-inside: avoid; clear:both">

          <h4>{{findCustomQuestionnaire(tab.id).name}}</h4>
          <div v-if="customQuestionnaireAnswers[tab.id][question.id]" v-for="(question,index) in findCustomQuestionnaire(tab.id).questionnaire.questions"
               :style="index % 2 === 1 ? 'background-color:#F8F9FC;' :''">
            <question-render-item :question="question" :value="customQuestionnaireAnswers[tab.id][question.id]"></question-render-item>
          </div>

        </div>
      </div>


    </div>
  </div>

</template>




<script>
import questionRender from "@components/QuestionRender";
import printHeader from "@components/print/header"
import PrintMixin from "@src/mixins/PrintMixin";
import QuestionRenderItem from "@components/QuestionRenderItem";
import Vue from "vue";
export default{
  props:{
    questionnaire: Object,
    shortQuestionnaire: Object,
    questionnaireStructure: Object,
    shortQuestionnaireStructure: Object,
    customQuestionnairesList: Array,
    customQuestionnaireAnswers: Object,
    athleteId: String,
  },
  mixins:[PrintMixin],
  components: {QuestionRenderItem, questionRender, printHeader},
  computed:{
    shareOptionsChanged(){
      return this.initialShareOptions !== JSON.stringify(this.shareOptions);
    },
    getShareLink(){
      return window.location.origin + '/shared-questionnaire?id=' + this.shareOptions.id + '&key='+ this.shareOptions.key
    }
  },
  data(){
    return{
      isPrinting: false,
      isPreview: false,
      shareOptions: null,
      initialShareOptions: null,
      printOptions: {
        shortQuestionnaire:{
          show: true,
          tab_0: true,
          tab_1: true,
          tab_2: true
        },
        questionnaire:{
          show: true,
          tab_1: true,
          tab_2: true,
          tab_3: true,
          tab_4: true,
          tab_5: true,
          tab_6: true
        },
        customQuestionnaires: {
          show: true
        }
      },
      copied: false
    }
  },
  watch:{

    printOptions:{
      handler(newValue, oldValue) {

        localStorage.setItem('questionnaires.printOptions', JSON.stringify(newValue));
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true

    }
  },

  mounted(){

    if(localStorage.getItem('questionnaires.printOptions')){
      this.printOptions = JSON.parse(localStorage.getItem('questionnaires.printOptions'));
    }
    this.getSharedQuestionnaireSettings();

  },
  methods:{
    saveIfChanged(){
      if(this.shareOptionsChanged) this.saveShareOptions();
    },
    copyKey(){
      this.saveIfChanged();
      navigator.clipboard.writeText(this.getShareLink);
      this.copied = true;
    },
    saveShareOptions(){
      this.$axios.put(`/athlete/${this.athleteId}/questionnaires/share`, this.shareOptions).then(result => {
        this.shareOptions = result.data;
        this.initialShareOptions = JSON.stringify(this.shareOptions);
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      })
    },
    openShareOptionsModal(){
      this.$refs['share-options'].show();
    },
    getSharedQuestionnaireSettings(){
      this.$axios.get(`/athlete/${this.athleteId}/questionnaires/share`).then(result => {

        this.shareOptions = result.data;

        this.initialShareOptions = JSON.stringify(this.shareOptions);

      }).catch(e=>{}).finally(()=>{
        if(this.shareOptions && !this.shareOptions.visible_questionnaires) this.shareOptions.visible_questionnaires = {...this.printOptions};

        for(let questionnaire of this.customQuestionnairesList){
          if(!this.printOptions.customQuestionnaires.hasOwnProperty(questionnaire.id)) this.printOptions.customQuestionnaires[questionnaire.id] = true;

          //we do not auto-share
          if(!this.shareOptions.visible_questionnaires.customQuestionnaires.hasOwnProperty(questionnaire.id)){
            Vue.set(this.shareOptions.visible_questionnaires.customQuestionnaires, questionnaire.id, false);
          }
        }

      })
    },
    findCustomQuestionnaire(id){
      return this.customQuestionnairesList.find(x => x.id === id);
    },
    findQuestion(id, question_id){
      let questionnaire = this.findCustomQuestionnaire(id);


      return questionnaire.questions[question_id];
    },
    printQuestionnaire(){

      this.$refs['print-options'].show();
    },

    previewQuestionnaire(){
      this.isPreview = true;
      this.$refs['preview-area'].show();
    },
    initializePrint(type) {
      this.isPrinting = true;

      this.$nextTick(() => {
        let divToPrint = this.$refs['questionnaires-print-area'].outerHTML;
        this.isPrinting = false;
        divToPrint = divToPrint.replaceAll("<p><br></p>", "");
        this.print(divToPrint, type, 'Ερωτηματολόγιο');
      })
    }
  },
}
</script>