<template>

  <div v-if="questionnaire[question.key] !== null && questionnaire[question.key] !== '' &&  question.type !== 'notice'"
  :style="index % 2 === 1 ? 'background-color:#F8F9FC;' :''">
      <question-render-item :index="index" :question="question" :value="questionnaire[question.key]" ></question-render-item>

      <div v-if="question.subQuestions">

        <div v-for="subQ of question.subQuestions">
          <question-render-item v-if="questionnaire[subQ.key] !== null && questionnaire[subQ.key] !== '' &&  subQ.type !== 'notice'
                                  && (question.type === 'YesNo' && (parseInt(questionnaire[question.key]) === 1) ||
                                  (question.type === 'Options' && questionnaire[question.key] === subQ.value))"
                                :index="index" :question="subQ" :value="questionnaire[subQ.key]" :is-sub="true"></question-render-item>

          <div v-for="sub_subQ of subQ.subQuestions">
            <question-render-item v-if="questionnaire[sub_subQ.key] !== null && questionnaire[sub_subQ.key] !== '' &&
              sub_subQ.type !== 'notice'
              && (subQ.type === 'YesNo' && (parseInt(questionnaire[subQ.key]) === 1) ||
                                  (subQ.type === 'Options' && questionnaire[subQ.key] === sub_subQ.value))
                                  " :index="index"
                                  :question="sub_subQ" :value="questionnaire[sub_subQ.key]" :is-sub="true" :is-sub-sub="true"></question-render-item>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import QuestionRenderItem from "@components/QuestionRenderItem";
export default{
  components: {QuestionRenderItem},
  props:{
    index:Number,
    question: Object,
    questionnaire: Object
  },

  methods:{

  }
}
</script>