<template>
    <div id="shared-questionnaire">


      <div v-if="hasError">

        <b-alert variant="danger" show>
          {{errorMessage}}
        </b-alert>



      </div>
      <div v-else>

        <athlete-questionnaire v-if="athlete"
                               :is-shared-questionnaire="true" :athlete="athlete"
                               :shared-question-settings="settings.settings"
        :shared-questionnaire-data="settings.questionnaireData" :shared-short-questionnaire-data="settings.shortQuestionnaireData"
        :shared-custom-questionnaires="settings.customQuestionnaires" :shared-custom-questionnaire-data="settings.customQuestionnaireAnswers"
        @updateClicked="updateQuestionnaire"></athlete-questionnaire>
      </div>




    </div>

</template>
<style lang="scss">
#shared-questionnaire{

  .questionnaire-tabs{
    > .col-auto{
      @media(max-width:900px){
        width:100%!important;
      }
    }
  }
  .card-body{
    @media(max-width:900px){
      padding:0.6rem;
    }
  }
  .questionnaire-tabs  >.col-auto .nav > .nav-item{
    background:transparent;
    a{
      color:#fff!important;
      &.active{
        color:$color_primary!important;
        background:#fff!important;
      }
    }
  }
}
</style>

<script>

import AthleteQuestionnaire from "@components/athletes/athleteQuestionnaire";
export default{
  components: {AthleteQuestionnaire},
  props:{

  },
  data(){
    return{
      athlete: null,
      hasError: false,
      errorMessage: null,
      settings: null,
      initialQuestionnaireState: null,
      initialShortQuestionnaireState: null
    }
  },
  mounted(){

    if(!this.$route.query.id || !this.$route.query.key){
      this.hasError = true;
      this.errorMessage = this.$t('errors.sharedQuestionnaireIncorrectParams')
      return;
    }
    this.getSharedInformation();

    setTimeout(()=>{
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
    }, 1500);

  },

  methods:{
    getSharedInformation(){

      this.$axios.get('/general/shared-questionnaire-settings?id='+this.$route.query.id +'&key='+this.$route.query.key).then(res=>{
        this.settings = res.data;

        this.initialQuestionnaireState = this.settings.questionnaireData;
        this.initialShortQuestionnaireState = this.settings.shortQuestionnaireData;

        this.athlete = {
          id: res.data.settings.athleteId
        }
      }).catch(e=>{
        this.hasError = true;
        this.errorMessage = this.$t('errors.sharedQuestionnaireIncorrectParams')
      })

    },

    updateQuestionnaire(data){


      try{
        let updates = {
          questionnaire: {},
          shortQuestionnaire: {},
          customQuestionnaireAnswers: {}
        };
        // find questionnaire update list
        for(let question of Object.keys(data.questionnaire)) {
          if (this.hasChanged(data.questionnaire[question], this.initialQuestionnaireState[question])) {
            updates.questionnaire[question] = data.questionnaire[question];
          }
        }
        // find questionnaire update list
        for(let question of Object.keys(data.shortQuestionnaire)) {
          if (this.hasChanged(data.shortQuestionnaire[question], this.initialShortQuestionnaireState[question])) {
            updates.shortQuestionnaire[question] = data.shortQuestionnaire[question];
          }
        }

        updates.customQuestionnaireAnswers = data.customQuestionnaireAnswers;



        this.$axios.put('/general/shared-questionnaire-settings', {
          id: this.$route.query.id,
          key: this.$route.query.key,
          updates
        }).then(()=>{
          this.getSharedInformation();
          this.key++;
          this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: this.$t('success.text')});
        }).catch(e=>{
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
        })
      }catch(e){
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      }

    },


    hasChanged(current, initial){

      if(current === null && initial === null) return false;
      if(current && initial === null) return true;
      if(initial && current === null) return true;

      return initial.toString() !== current.toString();

    }
  }
}
</script>