<template>
  <div style="display:flex;align-items:center;padding:5px 10px;">

    <div class="" style="width:400px;">   <span class="question" style="font-size:0.8rem;font-weight:300" :style="isSubSub ? 'padding-left:10px' : ''">
      {{isSub? '—': ''}} {{question.question}}</span></div>
    <div  style="width:350px;padding-left:15px; font-size:0.9rem;font-weight:600;">
        <span v-if="question.type === 'YesNo' || question.type === 'yes_no'">
          {{ parseInt(value)=== 0 ? 'Όχι' : 'Ναι' }}
        </span>
      <span v-if="question.type === 'text' || question.type === 'textarea' || question.type === 'long_text'">
          {{value}}
        </span>
      <span v-if="question.type === 'Options'" >

          {{findOption(value)}}
        </span>

      <span v-if="question.type === 'date'">
        {{formatDateWithoutHour(value)}}
      </span>
    </div>


  </div>
</template>

<script>
export default{
  
  props:{
    index: Number,
    question: Object,
    value: [String, Number],
    isSub: Boolean,
    isSubSub: Boolean
  },
  methods:{
    findOption(val){
      let option = this.question.options.find(x => parseInt(x.value) === parseInt(val));
      return option ? option.text : val
    }
  }
}
</script>